import React, { useState, useEffect, useReducer, useContext } from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ImportedContactModal from "../../components/ImportedContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal";
import { socketConnection } from "../../services/socket";

import {CSVLink} from "react-csv";
import ExternalDBSelectionModal from "../../components/ExternalDBSelectionModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [contactTicket, setContactTicket] = useState({});
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [externalDBSelectorOpen, setExternalDBSelectorOpen] = useState(false);
  const [externalDB, setExternalDB] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [importedRatio, setImportedRatio] = useState(0);
  const [importedRatioOpen, setImportedRatioOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/imported-contact/", {
            params: { searchParam, pageNumber },
          });
          console.log(data);
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);
  //MARK: Socket
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });
    socket.on(`company-${companyId}-importedcontact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.importedContact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: + data.contactId });
      }

      if(data.action === "restart-index"){
        toast.success("Se han actualizado los contactos importados");
        setPageNumber(0)
        setPageNumber(1)
      }
      if(data.action === "update_imported_ratio"){
        setImportedRatio(data.value*100)
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const getContact = async (importedContact) =>{
    try{
      const res = await api.post("/imported-contact/bind-to-contacts",{
        id: importedContact.id
      });
      if(res.status === 200){
        return res.data;
      }
    }catch(e){
      console.log(e?.response)
      if(e?.response?.data?.error?.message?.includes("ERR_NO_DEF_WAPP_FOUND")){
        toast.error("No hay conxiones de WhatsApp disponibles");
        return null;
      }
      toast.error("Error desconocido desde el servidor");
    }
  }

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleimportContact = async () => {
    try {
      console.log(externalDB)
      if(!externalDB){
        throw new Error("Base de Datos no valida")
      }
      setImportedRatioOpen(true)
      await api.post("/imported-contact/import/"+externalDB.id);
      setExternalDBSelectorOpen(false)
      history.go(0);
    } catch (err) {
      toast.error("Base de Datos no valida");
    } finally{
      setImportedRatioOpen(false)
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectDatabase = (value) => {
    setExternalDB(value);
    setConfirmOpen(true)
  }

  return (
    <MainContainer className={classes.mainContainer}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <ExternalDBSelectionModal 
        title={"Selecciona la Base de Datos"}
        open={externalDBSelectorOpen}
        onClose={()=>setExternalDBSelectorOpen(false)}
        onSend={handleSelectDatabase}
      />
      <ImportedContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ImportedContactModal>
      <Modal
        open={importedRatioOpen}
        onClose={()=>setImportedRatioOpen(false)}
      >
        <Box sx={{ width: 400, p: 2, bgcolor: 'background.paper', margin: 'auto', mt: '20%' }}>
          <LinearProgressWithLabel value={importedRatio} />
        </Box>
      </Modal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("syncContacts.confirmationModal.deleteTitle")} ${
                deletingContact.name
              }?`
            : `${i18n.t("syncContacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? ()=>{}
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("syncContacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("syncContacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("syncContacts.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("syncContacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={()=>setExternalDBSelectorOpen(true)}
          >
            {i18n.t("syncContacts.buttons.import")}
          </Button>

        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("syncContacts.table.databaseId")}</TableCell>
              <TableCell>{i18n.t("syncContacts.table.clientId")}</TableCell>
              <TableCell align="center">
                {i18n.t("syncContacts.table.whatsapp")}
              </TableCell>
              <TableCell>{i18n.t("syncContacts.table.firstName")}</TableCell>
              <TableCell>{i18n.t("syncContacts.table.commercialName")}</TableCell>
              <TableCell>{i18n.t("syncContacts.table.address")}</TableCell>
              <TableCell>{i18n.t("syncContacts.table.latitude")}</TableCell>
              <TableCell>{i18n.t("syncContacts.table.longitude")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.database.name}</TableCell>
                  <TableCell>{contact.clientId}</TableCell>
                  <TableCell align="center">{contact.whatsappNumber}</TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell>{contact.commercialName}</TableCell>
                  <TableCell>{contact.address}</TableCell>
                  <TableCell>{contact.latitude}</TableCell>
                  <TableCell>{contact.longitude}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={async () => {
                        const contactId = (await getContact(contact))?.id;
                        if(!contactId){
                          return;
                        }
                        setContactTicket({id:contactId});
                        setNewTicketModalOpen(true);
                      }}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
