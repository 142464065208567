import React, { useState, useEffect, useContext } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import { green } from "@material-ui/core/colors";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, FormControl, MenuItem, Select, FormHelperText } from "@material-ui/core";
import api from "../../services/api";
import { toast } from "react-toastify";
//import ShowTicketOpen from "../ShowTicketOpenModal";

const useStyles = makeStyles((theme) => ({
  online: {
    fontSize: 11,
    color: "#25d366"
  },
  offline: {
    fontSize: 11,
    color: "#e1306c"
  },
  root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ExternalDBSelectionModal = ({ title, open, onClose, onSend }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [elements, setElements] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (!open) {
      return;
    }
    api.get("/external-db/1").then((response)=>{
      setElements(response.data.records);
    }).catch((e)=>{
      toast.error("No fue posible obtener las bases de datos")
    })
  }, [open]);

  const handleClose = () => {
    onClose()
  };

  const handleSend = async () => {
    if(!selected){
      setError("Valor no valido")
      return;
    }
    await onSend(selected);
  }
 
  return (
    <>
      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper">
          <DialogTitle id="form-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent dividers>
            <FormControl margin="dense" variant="outlined" fullWidth>          
              <InputLabel id="db-selection-label" htmlFor="db-selection" >Base de Datos Externa</InputLabel>
              <Select
                  label="Dialecto de la Base de Datos"
                  labelId="db-selection-label"
                  id="db-selection"
                  error={Boolean(error)}
                  margin="dense"
                  onChange={(event)=>setSelected(event.target.value)}
                  value={selected?.id}
                  fullWidth
                  >
                { elements.map((element)=>{
                  return(<MenuItem key={element.id} value={element}>{element.name}</MenuItem>)
                })}
              </Select>
              { 
                Boolean(error) && 
                (<FormHelperText error>{error}</FormHelperText>) 
              }
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="outlined"
            >
              {i18n.t("contactModal.buttons.cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.btnWrapper}
              onClick={handleSend}
            >
              {"Enviar"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default ExternalDBSelectionModal;