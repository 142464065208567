import React, { useState, useEffect, useContext } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, Field } from "formik";
import { green } from "@material-ui/core/colors";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, FormControl, MenuItem, Select, FormHelperText } from "@material-ui/core";
//import ShowTicketOpen from "../ShowTicketOpenModal";

const useStyles = makeStyles((theme) => ({
  online: {
    fontSize: 11,
    color: "#25d366"
  },
  offline: {
    fontSize: 11,
    color: "#e1306c"
  },
  root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	btnWrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));


const schema = Yup.object().shape({
	host: Yup.string().required("Campo requerido"),
	dialect: Yup.string().required("Campo requerido"),
	username: Yup.string().required("Campo requerido"),
	password: Yup.string().required("Campo requerido"),
	name: Yup.string().required("Campo requerido"),
	port: Yup.number().integer("Puerto no valido")
});


const ExternalDBModal = ({ title, open, onClose, onSend, onTest,initialElement }) => {
  const classes = useStyles();
  const default_ = {
    name: "",
    username: "",
    password: "",
    host: "",
    port: "",
    dialect: ""
  }
  const touch_ = {
    name: true,
    username: true,
    password: true,
    host: true,
    port: true,
    dialect: true
  }

  useEffect(() => {
    if (!open) {
      return;
    }
  }, [open]);

  const handleClose = () => {
    onClose()
  };
 
  return (
    <>
      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper">
          <DialogTitle id="form-dialog-title">
            {title}
          </DialogTitle>
          <Formik
            initialValues={{
              ...default_,
              ...initialElement,
            }}
            validationSchema={schema}
            onSubmit={
              async (v,a) => {
                onSend(v);
              }
            }
            
          >
            {({ values, errors, touched, isSubmitting, setTouched }) => (
              <Form>
                <DialogContent dividers>
                  <Field
                    as={TextField}
                    label="Nombre de la Base de Datos"
                    name="name"
                    fullWidth
                    autoFocus
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label="Nombre de Usuario"
                    name="username"
                    fullWidth
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    type="password"
                    label="Contraseña"
                    name="password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label="Direección del Host"
                    fullWidth
                    name="host"
                    error={touched.host && Boolean(errors.host)}
                    helperText={touched.host && errors.host}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label="Puerto de conexión"
                    fullWidth
                    name="port"
                    type="number"
                    error={touched.port && Boolean(errors.port)}
                    helperText={touched.port && errors.port}
                    placeholder="5513"
                    variant="outlined"
                    margin="dense"
                  />
                  <FormControl margin="dense" variant="outlined" fullWidth>          
                    <InputLabel id="dialect-selection-label" >Dialecto de la Base de Datos</InputLabel>
                    <Field
                        as={Select}
                        name="dialect"
                        label="Dialecto de la Base de Datos"
                        labelId="dialect-selection-label"
                        fullWidth
                        error={touched.dialect && Boolean(errors.dialect)}
                        margin="dense"
                        >
                      <MenuItem value={'mysql'}>MySQL</MenuItem>
                      <MenuItem value={'postgres'}>PostgreSQL</MenuItem>
                      <MenuItem value={'sqlite'}>SQLite</MenuItem>
                      <MenuItem value={'mariadb'}>MariaDB</MenuItem>
                      <MenuItem value={'mssql'}>Microsoft SQL Server</MenuItem>
                    </Field>
                    {touched.dialect && errors.dialect && (
                      <FormHelperText error>{errors.dialect}</FormHelperText>
                    )}
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  
                  <Button
                    onClick={async()=>{
                      if(Object.keys(errors).length !== 0){
                        setTouched(touch_);
                        return;
                      }
                      await onTest(values);
                    }}
                    color="primary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    Probar
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                  >
                    {i18n.t("contactModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    ):"Enviar"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </>
  );
};
export default ExternalDBModal;