import React, { useState, useEffect } from "react";

import api from "../../services/api";

const ModalVideoCors = ({ url, className }) => {
	const [blobUrl, setBlobUrl] = useState("");

	useEffect(() => {
		if (!url) return;
			api.get(url, {
				responseType: "blob",
			}).then(({data, headers})=>{
				const url = window.URL.createObjectURL(
					new Blob([data], { type: headers["content-type"] })
				);
				setBlobUrl(url);
			}).catch((error) => {
				console.error(error);
			})
			
			
	}, [url]);

	return (
		<video
          className={className}
          src={blobUrl}
          controls
        />
	);
};

export default ModalVideoCors;
