// React component to manage permissions 
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../context/Auth/AuthContext";

function PolicyManager({children, permissionKey}) {
    const { permissions } = useContext(AuthContext);
    if (permissions[permissionKey]){
        return children;
    }else{
        return null;
    }
}

export default PolicyManager;